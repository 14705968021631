import React, { useState } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Logo from "../../../../assets/images/logonegro.png";
import {
  logout,
  useAuthDispatch,
  useAuthState,
} from "../../../Context/LoginRegister";
import AccountBoxIcon from "@material-ui/icons/AccountBox";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { NavDropdown } from "react-bootstrap";
const Nav = styled.div`
  background: white !important;
  height: 64px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const NavIcon = styled(Link)`
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 2rem;
  height: 64px;
  display: flex;
  background: transparent;
  justify-content: space-between;
  align-items: center;
  outline: none;
`;

const SidebarNav = styled.nav`
  background: white !important;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  padding: 0px;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms ease;
  z-index: 10;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const Sidebar = () => {
  const history = useHistory();
  const dispatch = useAuthDispatch();
  const stateAuth = useAuthState();

  const [sidebar, setSidebar] = useState(false);

  const handleLogout = () => {
    logout(dispatch);
    history.push("/login");
  };
  const showSidebar = () => setSidebar(!sidebar);
  // #007b82
  return (
    <div>
      <Nav>
        <NavIcon>
          <MenuIcon style={{ color: "#007b82" }} onClick={showSidebar} />
        </NavIcon>
        <img alt="" className="imgLogo" src={Logo} />
        <div
          style={{
            background: "transparent",
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <NavDropdown
            color={"black"}
            title={stateAuth.user.nombre + " " + stateAuth.user.apellido + " "}
            style={{
              backgroundColor: "white !important",
              color: "black !important",
              textDecoration: "none",
            }}
          >
            <NavDropdown.Item
              style={{ backgroundColor: "white", color: "black" }}
              onClick={handleLogout}
            >
              <ExitToAppIcon /> Cerrar Sesión
            </NavDropdown.Item>
          </NavDropdown>
        </div>
      </Nav>
      <SidebarNav sidebar={sidebar}>
        <SidebarWrap>
          <NavIcon>
            <CloseIcon onClick={showSidebar} />
          </NavIcon>
          {SidebarData.map((item, index) => {
            return <SubMenu item={item} key={index} />;
          })}
          <div onClick={handleLogout}>
            <SubMenu
              item={{ title: "Cerrar Sesion", icon: <AccountBoxIcon /> }}
            />
          </div>
        </SidebarWrap>
      </SidebarNav>
    </div>
  );
};

export default Sidebar;
