import React, {  useState } from 'react';
import { Container } from 'react-bootstrap';
import './Galeria.css';
import { motion,  } from 'framer-motion';
import ModalGaleria from './ModalGaleria';
import GridImg from './GridImg';

// const titleVariants = {
// 	hidden: { opacity: 0 },
// 	visible: {
// 		opacity: 1
// 	}
// };
export default function Galeria() {
	// const [ images, setImages ] = useState(IMAGES);
	const [ selectedImg, setSelectedImg ] = useState(null);

	return (
		<Container
			className="d-none d-sm-none d-md-block d-lg-block d-xl-block containerGaleria"
			fluid
			style={{ backgroundColor: 'transparent', padding: '0px' }}
		>
			<motion.div className="titulo" initial="hidden" transition={{ duration: 0.5 }}>
				Galería
			</motion.div>

			<br />
			<GridImg setSelectedImg={setSelectedImg} />
			{selectedImg && <ModalGaleria selectedImg={selectedImg} setSelectedImg={setSelectedImg} />}
			{/* <Gallery enableImageSelection={false} margin={0} rowHeight={330} images={images} /> */}
		</Container>
	);
}
