import React from 'react';
import { motion } from 'framer-motion';

export default function ModalGaleria({ setSelectedImg, selectedImg }) {
	const handleClick = (e) => {
		if (e.target.classList.contains('backdrop')) {
			document.body.style.overflow = 'unset';

			setSelectedImg(null);
		}
	};

	return (
		<motion.div className="backdrop" onClick={handleClick}  initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
			<motion.img src={selectedImg} alt="enlarged pic" initial={{ y: '-30vh' }} animate={{ y: 0 }} />
		</motion.div>
	);
}
