import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Col, Form, Modal, ProgressBar, Spinner } from 'react-bootstrap';
import { Formik } from 'formik'; // Importamos el component <Formik />
import axios from 'axios';
import { useAuthState } from '../../../Context/LoginRegister';

const yup = require('yup');
const schema = yup.object({
	titulo: yup
		.string()
		.required('Ingrese un titulo..')
		.min(2, 'El titulo tiene que ser mayor a 2 caracteres..')
		.max(100, 'El titulo tiene que ser menor a 100 caracteres..'),
	subtitulo: yup
		.string()
		.required('Ingrese un subtitulo..')
		.min(2, 'El subtitulo tiene que ser mayor a 2 caracteres..')
		.max(150, 'El subtitulo tiene que ser menor a 150 caracteres..'),
	cuerpo: yup
		.string()
		.required('Ingrese el contenido..')
		.min(2, 'El contenido tiene que ser mayor a 2 caracteres..')
		.max(1000, 'El contenido tiene que ser menor a 1000 caracteres..')
	// imagenes: yup
	// 	.string()
	// 	.required('Ingrese el contenido..')
	// 	.min(2, 'El contenido tiene que ser mayor a 2 caracteres..')
	// 	.max(250, 'El contenido tiene que ser menor a 250 caracteres..')
});

export default function ModalNuevo({ show, handleClose, handleIsNew, handleGetNoticias }) {
	const [ selectedFiles, setSelectedFiles ] = useState([]);
	const [ isSelected, setIsSelected ] = useState();
	const userDetails = useAuthState();
	const [ uploadPercentage, setUploadPercentage ] = useState();
	const [ messageResponse, setMessageResponse ] = useState();
	const [ loading, setLoading ] = useState(false);

	const formImg = useRef('');

	const removeItemFromArr = (item) => {
		console.log(item);
		var i = selectedFiles.indexOf(item);
		console.log(i);
		console.log(selectedFiles.length);
		console.log(selectedFiles);
		let newArray = selectedFiles.filter((s) => {
			return s !== item;
		});
		setSelectedFiles(Array.from(newArray));
		console.log(newArray);
		// setSelectedFiles(selectedFiles.splice(i, 1));
	};
	const changeHandler = (event) => {
		setIsSelected(true);
		console.log(event.target.files);
		let files = Array.from(event.target.files);
		console.log(files);
		console.log([ 1, 2, 3, 4 ]);

		setSelectedFiles(Array.from(files));
		console.log(Array.isArray(selectedFiles));
		// console.log(event.target.files[0]);
		setIsSelected(true);
	};

	const handleSubmit = async (values, { resetForm }) => {
		setLoading(true);

		console.log(values.titulo, values.subtitulo, values.cuerpo);
		let fD = new FormData();
		if (isSelected) {
			selectedFiles.forEach((foto) => {
				fD.append('fotos[]', foto);
			});
		}
		fD.append('titulo', values.titulo);
		fD.append('subtitulo', values.subtitulo);
		fD.append('cuerpo', values.cuerpo);
		fD.append('fecha', values.fecha);
		fD.append('hora', values.hora);
		fD.append('visible', 1);
		fD.append('eliminada', 0);
		fD.append('id', userDetails.user.id);
	
		const options = {
			onUploadProgress: (progressEvent) => {
				const { loaded, total } = progressEvent;
				let percent = Math.floor(loaded * 100 / total);
				console.log(`${loaded}kb of ${total}kb | ${percent}%`);
				if (percent < 100) {
					setUploadPercentage(percent);
				}
			},
			headers: {
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
			}
		};
		await axios
			.post('https://api.estudioeje.com.ar/api/noticias', fD, options)
			.then((res) => {
				setLoading(false);

				setUploadPercentage(100);
				console.log(res);
				console.log(res.data.message);
				setMessageResponse(res.data.message);
				handleGetNoticias();
				setTimeout(() => {
					setUploadPercentage(0);
					setMessageResponse(null);
					setSelectedFiles([]);
				}, 2000);
			})
			.catch((e) => {
				setLoading(false);
				console.log(e);
				setMessageResponse(e.message);
			});
	};

	return (
		<div>
			<Modal keyboard={false} backdrop="static" size="xl" show={show} onHide={handleClose}>
				<Modal.Header>
					<Modal.Title>Nueva Noticia</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Formik
						validationSchema={schema}
						onSubmit={handleSubmit}
						initialValues={{
							titulo: 'Titulo',
							subtitulo: 'Subtitulo',
							cuerpo: 'Cuerpo',
							fecha: new Date().toISOString().split('T')[0],
							hora: '13:00'
						}}
					>
						{({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
							<Form noValidate onSubmit={handleSubmit} className="pl-md-5 pr-md-5">
								<Form.Row>
									<Form.Group as={Col} md="12" controlId="validationFormik01">
										{/* <Form.Label>First name</Form.Label> */}
										<Form.Text
											className="text-muted h5"
											style={{ color: 'rgba(66,62,55,.2) !important' }}
										>
											Titulo
										</Form.Text>
										<Form.Control
											type="text"
											name="titulo"
											value={values.titulo}
											onChange={handleChange}
											isValid={touched.titulo && !errors.titulo}
											isInvalid={!!errors.titulo}
											style={{
												border: '0',
												borderBottom: '1px solid rgba(66,62,55,.2)',
												borderRadius: 0
											}}
										/>
										<Form.Control.Feedback>Correcto!</Form.Control.Feedback>
										<Form.Control.Feedback type="invalid">{errors.titulo}</Form.Control.Feedback>
									</Form.Group>
									<Form.Group as={Col} md="12" controlId="validationFormik02">
										{/* <Form.Label>Last name</Form.Label> */}
										<Form.Text
											className="text-muted h5"
											style={{ color: 'rgba(66,62,55,.2) !important' }}
										>
											Subtitulo
										</Form.Text>
										<Form.Control
											type="text"
											name="subtitulo"
											value={values.subtitulo}
											onChange={handleChange}
											isValid={touched.subtitulo && !errors.subtitulo}
											isInvalid={!!errors.subtitulo}
											style={{
												border: '0',
												borderBottom: '1px solid rgba(66,62,55,.2)',
												borderRadius: 0
											}}
										/>
									
										<Form.Control.Feedback>Correcto!</Form.Control.Feedback>
										<Form.Control.Feedback type="invalid">{errors.subtitulo}</Form.Control.Feedback>
									</Form.Group>
									<Form.Group as={Col} md="12" controlId="validationFormik03">
										{/* <Form.Label>cuerpo</Form.Label> */}
										<Form.Text
											className="text-muted h5"
											style={{ color: 'rgba(66,62,55,.2) !important' }}
										>
											Cuerpo
										</Form.Text>
										<Form.Control
											type="cuerpo"
											name="cuerpo"
											value={values.cuerpo}
											onChange={handleChange}
											isValid={touched.cuerpo && !errors.cuerpo}
											isInvalid={!!errors.cuerpo}
											style={{
												border: '0',
												borderBottom: '1px solid rgba(66,62,55,.2)',
												borderRadius: 0
											}}
										/>
									

										<Form.Control.Feedback>Correcto!</Form.Control.Feedback>
										<Form.Control.Feedback type="invalid">{errors.cuerpo}</Form.Control.Feedback>
									</Form.Group>
									<Form.Group as={Col} md="12" controlId="validationFormik01">
										{/* <Form.Label>First name</Form.Label> */}
										<Form.Text
											className="text-muted h5"
											style={{ color: 'rgba(66,62,55,.2) !important' }}
										>
											Fecha
										</Form.Text>
										<Form.Control
											type="date"
											name="fecha"
											value={values.fecha}
											onChange={handleChange}
											isValid={touched.fecha && !errors.fecha}
											isInvalid={!!errors.fecha}
											style={{
												border: '0',
												borderBottom: '1px solid rgba(66,62,55,.2)',
												borderRadius: 0
											}}
										/>
								
										<Form.Control.Feedback>Correcto!</Form.Control.Feedback>
										<Form.Control.Feedback type="invalid">{errors.fecha}</Form.Control.Feedback>
									</Form.Group>
									<Form.Group as={Col} md="12" controlId="validationFormik01">
										{/* <Form.Label>First name</Form.Label> */}
										<Form.Text
											className="text-muted h5"
											style={{ color: 'rgba(66,62,55,.2) !important' }}
										>
											Hora
										</Form.Text>
										<Form.Control
											type="time"
											name="hora"
											value={values.hora}
											onChange={handleChange}
											isValid={touched.hora && !errors.hora}
											isInvalid={!!errors.hora}
											style={{
												border: '0',
												borderBottom: '1px solid rgba(66,62,55,.2)',
												borderRadius: 0
											}}
										/>
									
										<Form.Control.Feedback>Correcto!</Form.Control.Feedback>
										<Form.Control.Feedback type="invalid">{errors.hora}</Form.Control.Feedback>
									</Form.Group>
									<Form.Group as={Col} md="12" controlId="validationFormik04">
										<Form.Control
											ref={formImg}
											multiple
											name="fotos[]"
											type="file"
											onChange={changeHandler}
										/>
										<div style={{ display: 'flex', flexWrap: 'wrap' }}>
											{isSelected ? (
												selectedFiles.map((selectedFileInd, index) => {
													return (
														<div key={index}>
															<img
																style={{ width: '12vw', height: 'auto' }}
																src={URL.createObjectURL(selectedFileInd)}
															/>
															<Button
																variant="danger"
																size="sm"
																onClick={() => {
																	removeItemFromArr(selectedFileInd);
																}}
															>
																x
															</Button>
															{/* <p>Filename: {selectedFileInd.name}</p>
														<p>Filetype: {selectedFileInd.type}</p>
														<p>Size in bytes: {selectedFileInd.size}</p> */}
														</div>
													);
												})
											) : (
												<p>Select a file to show details</p>
											)}
										</div>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col} md="12" className="pr-5">
										<Button className="float-md-right btn-enviar" type="submit">
											{loading ? (
												<Spinner
													animation="border"
													size="sm"
													style={{ marginBottom: '2px', marginRight: '3px' }}
												/>
											) : (
												<div style={{ display: 'none' }} />
											)}
											Crear Noticia
										</Button>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col} md="12" className="pr-5">
										{uploadPercentage > 0 && (
											<ProgressBar
												variant="info"
												active
												label={`${uploadPercentage}`}
												now={uploadPercentage}
											/>
										)}
									</Form.Group>
								</Form.Row>
								<Form.Row>
									{messageResponse != null ? (
										<Alert variant="info" className="w-100 ">
											{messageResponse}
										</Alert>
									) : (
										<div />
									)}
								</Form.Row>
							</Form>
						)}
					</Formik>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="primary btn-enviar"
						onClick={() => {
							handleIsNew(false);
							handleClose(false);
						}}
					>
						Volver
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}
