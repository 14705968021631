import React, { useEffect, useState } from 'react';
import { Container, InputGroup, Table, Button } from 'react-bootstrap';
import ModalEditNovedad from '../ModalEditNovedad/ModalEditNovedad';
import ModalNuevoNovedad from '../ModalNuevoNovedad/ModalNuevoNovedad';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import axios from 'axios';
import ModalDeleteNovedad from '../ModalDeleteNovedad/ModalDeleteNovedad';

export default function NovedadesAdmin() {
	const [ loadingNoticias, setLoadingNoticias ] = useState(false);
	const [ novedadesAPI, setNovedadesAPI ] = useState([]);
	const [ novedad, setNovedad ] = useState({});

	const [ showNew, setShowNew ] = useState(false);
	const [ showEdit, setShowEdit ] = useState(false);
	const [ showDelete, setShowDelete ] = useState(false);
	const [ isNew, setIsNew ] = useState(false);
	const [ isEdit, setIsEdit ] = useState(false);
	const [ isDelete, setIsDelete ] = useState(false);
	const handleCloseDelete = () => setShowDelete(false);
	const handleIsDelete = () => setIsDelete(false);

	const handleCloseEdit = () => setShowEdit(false);
	const handleCloseNew = () => setShowNew(false);
	const handleIsNew = () => setIsNew(false);
	const handleIsEdit = () => setIsEdit(false);

	const getNovedades = async () => {
		setLoadingNoticias(true);
		await axios
			.get(`https://api.estudioeje.com.ar/api/novedades`, {
				headers: {
					Accept: 'application/json',
					Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
				}
			})
			.then((res) => {
				console.log(res.data);
				if (res.data != null) {
					setNovedadesAPI(res.data.novedades);
				}
				setLoadingNoticias(false);
			});
	};

	useEffect(() => {
		getNovedades();
	}, []);

	return (
		<div>
			<Container fluid style={{ overflowX: 'visible !important' }}>
				<Button
					className="bg-teal btn-enviar"
					style={{}}
					onClick={() => {
						setIsNew(true);
						setShowNew(true);
					}}
				>
					+ Novedad
				</Button>

				<h2>Listado de todas las novedades registradas</h2>
				<Table responsive="sm" striped bordered hover size="sm" className="w-100">
					<thead>
						<tr>
							<th>Titulo</th>
							<th>Cuerpo</th>
							<th>Fecha</th>
							<th>Hora</th>
							<th>Fecha Inicio</th>
							<th>Fecha Fin</th>
							<th>Hora Inicio</th>
							<th>Hora Fin</th>
							<th>Eliminada</th>
							<th>Accion</th>
						</tr>
					</thead>
					<tbody>
						{novedadesAPI.map((novedad) => {
							return (
								<tr key={novedad.id}>
									<td>{novedad.titulo}</td>
									<td>{novedad.cuerpo}</td>
									<td>{novedad.fecha}</td>
									<td>{novedad.hora}</td>
									<td>{novedad.fechainicio}</td>
									<td>{novedad.fechafin}</td>
									<td>{novedad.horainicio}</td>
									<td>{novedad.horafin}</td>
									<td>
										<InputGroup className="mb-3">
											<InputGroup.Prepend>
												{novedad.eliminada === 0 ? (
													<InputGroup.Checkbox aria-label="Checkbox for following text input" />
												) : (
													<InputGroup.Checkbox
														checked
														aria-label="Checkbox for following text input"
													/>
												)}
											</InputGroup.Prepend>
										</InputGroup>
									</td>
									<td style={{ display: 'flex' }}>
										<div
											className="bg-info"
											style={{
												margin: '2px',
												padding: '3px',
												borderRadius: '3px',
												cursor: 'pointer',
												color: 'white',
												boxShadow: ' 0px 0px 3px 1px rgba(0,0,0,.3)'
											}}
										>
											<EditIcon
												onClick={() => {
													setNovedad(novedadesAPI.find((nov) => nov.id === novedad.id));
													setIsEdit(true);
													setShowEdit(true);
												}}
											/>
										</div>
										<div
											className="bg-danger"
											style={{
												margin: '2px',
												padding: '3px',
												borderRadius: '3px',
												cursor: 'pointer',
												color: 'white',
												boxShadow: ' 0px 0px 3px 1px rgba(0,0,0,.3)'
											}}
										>
											<DeleteIcon
												onClick={() => {
													setNovedad(novedad);
													setIsDelete(true);
													setShowDelete(true);
												}}
											/>
										</div>
									</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
			</Container>
			{isDelete ? (
				<ModalDeleteNovedad
					handleIsDelete={handleIsDelete}
					novedad={novedad}
					show={showDelete}
					handleClose={handleCloseDelete}
					handleGetNovedades={getNovedades}
				/>
			) : (
				<div />
			)}
			{isEdit ? (
				<ModalEditNovedad
					handleIsEdit={handleIsEdit}
					novedad={novedad}
					handleGetNovedades={getNovedades}
					show={showEdit}
					handleClose={handleCloseEdit}
				/>
			) : (
				<div />
			)}
			{isNew ? (
				<ModalNuevoNovedad
					handleGetNovedades={getNovedades}
					handleIsNew={handleIsNew}
					show={showNew}
					handleClose={handleCloseNew}
				/>
			) : (
				<div />
			)}
		</div>
	);
}
