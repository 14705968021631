import React, { useEffect, useState } from "react";
import axios from "axios";
import { Col, Container, Row } from "react-bootstrap";
import "./Noticias.css";
import { motion } from "framer-motion";
import { animationThree, transition } from "../Animations/animation";
import NavBar2 from "../NavBar/NavBar2";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      staggerChildren: 0.5,
    },
  },
};

const item = {
  hidden: { x: 100, opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
  },
};

export default function Noticias() {
  const [loadingNoticias, setLoadingNoticias] = useState(false);
  const [loadingNovedades, setLoadingNovedades] = useState(false);
  const [noticiasAPI, setNoticiasAPI] = useState([]);
  const [novedadesAPI, setNovedadesAPI] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getNovedades = async () => {
      setLoadingNovedades(true);
      //await axios.get(`http://estudioeje.com/api/novedades.php?lim=9`).then((res) => {
      await axios
        .get(`https://api.estudioeje.com.ar/api/novedadesWeb`, {
          params: { limit: 6 },
        })
        .then((res) => {
          // console.log(res.data);
          if (res.data != null) {
            setNovedadesAPI(res.data.novedades);
          }

          setLoadingNovedades(false);
        });
    };
    getNovedades();
  }, []);

  useEffect(() => {
    const getNoticias = async () => {
      setLoadingNoticias(true);
      //await axios.get(`http://estudioeje.com/api/noticias.php`).then((res) => {
      await axios
        .get(`https://api.estudioeje.com.ar/api/noticiasWeb`)
        .then((res) => {
          //console.log(res.data);
          if (res.data != null) {
            setNoticiasAPI(res.data.noticias);
          }
          setLoadingNoticias(false);
        });
    };
    getNoticias();
  }, []);

  const novedadesSuperior = novedadesAPI.map((nov) => {
    return (
      <motion.li
        key={nov.id}
        variants={item}
        className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 "
      >
        <Row
          style={{
            // backgroundColor: 'green',
            paddingLeft: "7.5px",
            paddingRight: "7.5px",
            paddingBottom: "7.5px",
            paddingTop: "7.5px",
          }}
        >
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{
              border: "1px solid rgba(0,0,0,.15)",
              // backgroundColor: 'lime',
              paddingTop: 5,
              paddingLeft: 8,
              paddingRight: 8,
            }}
            className="colNoticiaSuperiorTexto"
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "transparent",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <div className="tituloNoticiaSuperior">{nov.titulo}</div>
              <div className="subTituloNoticiaSuperior">{nov.cuerpo}</div>
            </div>
          </Col>
        </Row>
      </motion.li>
    );
  });

  const noticiasInferior = noticiasAPI.map((not) => {
    return (
      <motion.li
        key={not.id}
        variants={item}
        className="col-12 col-sm-12 col-md-12 col-lg-12 mt-2 mb-2"
        style={{ borderBottom: "1px solid gray" }}
      >
        <Row>
          <Col
            xs={12}
            sm={12}
            md={5}
            xl={4}
            className="colNoticiaInferiorImagen"
          >
            <div
              style={{
                // backgroundImage: `${not.noticia_imagenes.length > 0
                // 	? `url(http://estudioeje.com/${not.noticia_imagenes[0].enlace})`
                // 	: `src("../../assets/images/derecho-civil.jpg")`}`,
                backgroundImage: `${
                  not.noticia_imagenes.length > 0
                    ? `url(${not.noticia_imagenes[0].enlace})`
                    : `src("../../assets/images/derecho-civil.jpg")`
                }`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: "100%",
                // backgroundColor:"red"
              }}
            />
          </Col>
          <Col
            xs={12}
            sm={12}
            md={7}
            xl={8}
            style={{ paddingLeft: 0, paddingRight: 0 }}
            className="colNoticiaInferiorTexto"
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                backgroundColor: "transparent",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                paddingTop: "10px",
              }}
              className="pl-3 pr-3"
            >
              <div className="tituloNoticiaInferior">
                <Link
                  to={`/noticia/${not.id}`}
                  className="tituloNoticiaInferior"
                >
                  {not.titulo}
                </Link>
              </div>
              <div className="subTituloNoticiaInferior">{not.subtitulo}</div>
            </div>
          </Col>
        </Row>
      </motion.li>
    );
  });

  return (
    <motion.div
      initial="out"
      animate="end"
      exit="out"
      variants={animationThree}
      transition={transition}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Estudio Eje - Noticias</title>
        <link rel="canonical" href="https://estudioeje.com.ar/noticias" />
      </Helmet>
      <NavBar2 />
      <Container className="sectionA" fluid>
        <div className="shapeGris" />
        <div className="shapeVerde" />
        <Container
          style={{ backgroundColor: "transparent", paddingTop: "64px" }}
          className="pl-0 pr-0"
        >
          <div
            style={{
              color: "rgba(0,123,130)",
              fontSize: "calc(0.8rem + 0.8vw)",
              fontWeight: "bolder",
              marginTop: "15px",
              marginBottom: "15px",
            }}
          >
            NOVEDADES
          </div>

          <motion.ul
            variants={container}
            initial="hidden"
            animate={novedadesAPI.length > 0 && "visible"}
            className="row pl-2 pr-2 "
            style={{ listStyle: "none" }}
          >
            {novedadesSuperior}
          </motion.ul>

          <div style={{ height: "1px", backgroundColor: "rgba(0,0,0,.3)" }} />
          <div
            style={{
              color: "rgba(0,123,130)",
              fontSize: "calc(0.8rem + 0.8vw)",
              fontWeight: "bolder",
              marginTop: "15px",
              marginBottom: "15px",
            }}
          >
            NOTICIAS
          </div>

          <motion.ul
            variants={container}
            initial="hidden"
            animate={noticiasAPI.length > 0 && "visible"}
            className="row pl-0"
            style={{ listStyle: "none" }}
          >
            {noticiasInferior}
          </motion.ul>
        </Container>
      </Container>
    </motion.div>
  );
}
