import { Button, Modal } from 'react-bootstrap';
import React from 'react';

export default function ModalServicio({ servicio, show, handleClose }) {
	return (
		<div>
			<Modal size={'lg'} show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>{servicio.nombre}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div dangerouslySetInnerHTML={{ __html: servicio.cuerpo }} />
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary btn-enviar" onClick={handleClose}>
						Volver
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}
