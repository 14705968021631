import React from "react";
import { Carousel } from "react-responsive-carousel";

export default function GaleriaCarousel() {
  const IMAGES = [
    {
      src: require("../../assets/images/galeria/gal3.jpeg"),
    },
    {
      src: require("../../assets/images/galeria/gal1.jpeg"),
    },
    {
      src: require("../../assets/images/galeria/gal5.jpeg"),
    },
    {
      src: require("../../assets/images/galeria/gal7.jpeg"),
    },
    {
      src: require("../../assets/images/galeria/galeria4.jpg"),
    },

    {
      src: require("../../assets/images/galeria/galeria11.jpg"),
    },
    {
      src: require("../../assets/images/galeria/galeria2.jpg"),
    },
  ];
  return (
    <div>
      <div
        className="titulo d-block d-sm-block d-md-none d-lg-none d-xl-none"
        initial="hidden"
        transition={{ duration: 0.5 }}
      >
        Galería
      </div>
      <Carousel
        showThumbs={false}
        className="d-block d-sm-block d-md-none d-lg-none d-xl-none"
      >
        {IMAGES.map((img, index) => {
          return (
            <div key={index}>
              <img
                alt=""
                style={{ objectFit: "contain", height: "40vh" }}
                src={img.src}
              />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
}
