import React from 'react';
import { Container } from 'react-bootstrap';
import './QuienesSomos.css';
import Fade from 'react-reveal/Fade';

export default function QuienesSomos() {
	return (
		<Container fluid className="containerQuienes" name="qsomos">
			<Fade cascade delay={500}>
				<div className="tituloTextoQuienes ">Quienes somos</div>
			</Fade>
			<div
				style={{
					backgroundColor: 'black',
					clipPath: 'polygon(0 100%, 100% 76%, 100% 0, 0 0)'
				}}
			>
				<div className="containerTxtQuienes" />
			</div>

			<div className="containerTxtInfo">
				<Fade delay={500}>
					<div className="subtituloTextoQuienes">
						JUVENTUD y EXPERIENCIA, son dos cualidades que normalmente no se asocian, pero en ESTUDIO EJE
						conviven de manera armoniosa y productiva.
						<br />
						<br />
						Somos un equipo de abogados.
					</div>
				</Fade>
			</div>
		</Container>
	);
}
