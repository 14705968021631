import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Form,
  Modal,
  ProgressBar,
  Spinner,
} from "react-bootstrap";
import { Formik } from "formik"; // Importamos el component <Formik />
import axios from "axios";
import { useAuthState } from "../../../Context/LoginRegister";
import { useHistory } from "react-router";

const yup = require("yup");

const schema = yup.object({
  titulo: yup
    .string()
    .required("Ingrese un titulo..")
    .min(2, "El titulo tiene que ser mayor a 2 caracteres..")
    .max(100, "El titulo tiene que ser menor a 100 caracteres.."),
  subtitulo: yup
    .string()
    .required("Ingrese un subtitulo..")
    .min(2, "El subtitulo tiene que ser mayor a 2 caracteres..")
    .max(150, "El subtitulo tiene que ser menor a 150 caracteres.."),
  cuerpo: yup
    .string()
    .required("Ingrese el contenido..")
    .min(2, "El contenido tiene que ser mayor a 2 caracteres..")
    .max(1000, "El contenido tiene que ser menor a 1000 caracteres.."),
});

export default function ModalEdit({
  noticia,
  show,
  handleClose,
  handleIsEdit,
  handleGetNoticias,
}) {
  const [isSelected, setIsSelected] = useState();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadPercentage, setUploadPercentage] = useState();
  const [messageResponse, setMessageResponse] = useState();
  const [imageToDelete, setImageToDelete] = useState();
  const [loading, setLoading] = useState(false);
  const [noticiaAPI, setNoticiaAPI] = useState(noticia);
  const [showEliminar, setShowEliminar] = useState(false);
  const handleCloseEliminar = () => setShowEliminar(false);
  const handleShowEliminar = () => setShowEliminar(true);

  const userDetails = useAuthState();
  let history = useHistory();
  console.log(noticia);
  console.log(noticiaAPI);

  const getNoticias = async () => {
    await axios
      .get(`http://localhost:8000/api/noticias/${noticia.id}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        setNoticiaAPI(res.data.noticia[0]);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const removeItemFromArr = (item) => {
    console.log(item);
    var i = selectedFiles.indexOf(item);
    console.log(i);
    console.log(selectedFiles.length);
    console.log(selectedFiles);
    let newArray = selectedFiles.filter((s) => {
      return s !== item;
    });
    setSelectedFiles(Array.from(newArray));
    console.log(newArray);
    // setSelectedFiles(selectedFiles.splice(i, 1));
  };

  const eliminarImgNoticia = (item) => {
    console.log(item);
    var i = selectedFiles.indexOf(item);
    console.log(i);
    console.log(selectedFiles.length);
    console.log(selectedFiles);
    let newArray = selectedFiles.filter((s) => {
      return s !== item;
    });

    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        console.log(`${loaded}kb of ${total}kb | ${percent}%`);
        if (percent < 100) {
          setUploadPercentage(percent);
        }
      },
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
    };
    axios
      .delete(
        `https://api.estudioeje.com.ar/api/noticias-imagenes/${imageToDelete}`,
        options
      )
      .then((res) => {
        console.log(res);
        console.log(res.data.message);
        getNoticias();
        setMessageResponse(res.data.message);
        setUploadPercentage(100);

        setTimeout(() => {
          setUploadPercentage(0);
          setSelectedFiles([]);
          setShowEliminar(false);
        }, 1000);
      })
      .catch((e) => {
        console.log(e);
        setMessageResponse(e.message);
      });
    setSelectedFiles(Array.from(newArray));
    console.log(newArray);
    // setSelectedFiles(selectedFiles.splice(i, 1));
  };
  const changeHandler = (event) => {
    setIsSelected(true);
    console.log(event.target.files);
    let files = Array.from(event.target.files);
    console.log(files);
    console.log([1, 2, 3, 4]);
    setSelectedFiles(Array.from(files));
    console.log(Array.isArray(selectedFiles));
    // console.log(event.target.files[0]);
    setIsSelected(true);
  };

  const handleSubmit = async (values) => {
    setLoading(true);

    console.log(values.titulo, values.visible, values.eliminada);
    console.log(noticia);
    let formData = new FormData();
    if (isSelected) {
      selectedFiles.forEach((foto) => {
        formData.append("fotos[]", foto);
      });
    }
    formData.append("titulo", values.titulo);
    formData.append("subtitulo", values.subtitulo);
    formData.append("cuerpo", values.cuerpo);
    formData.append("fecha", values.fecha);
    formData.append("hora", values.hora);
    formData.append("visible", values.visible);
    formData.append("eliminada", values.eliminada);
    formData.append("user_id", userDetails.user.id);
    formData.append("_method", "put");
    for (let pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        console.log(`${loaded}kb of ${total}kb | ${percent}%`);
        if (percent < 100) {
          setUploadPercentage(percent);
        }
      },
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
    };
    await axios
      .post(
        `https://api.estudioeje.com.ar/api/noticias/${noticia.id}`,
        formData,
        options
      )
      .then((res) => {
        console.log(res);
        setLoading(false);

        setMessageResponse(res.data.message);
        setUploadPercentage(100);
        getNoticias();
        handleGetNoticias();

        setTimeout(() => {
          setUploadPercentage(0);
          setMessageResponse(null);
          setSelectedFiles([]);
        }, 1000);
        //	history.push('/admin');
      })
      .catch((e) => {
        setLoading(false);

        setMessageResponse(e.message);
      });
  };

  return (
    <div>
      <Modal
        keyboard={false}
        backdrop="static"
        size="xl"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title>Editar Noticia</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            validationSchema={schema}
            onSubmit={handleSubmit}
            initialValues={{
              titulo: noticiaAPI.titulo,
              subtitulo: noticiaAPI.subtitulo,
              cuerpo: noticiaAPI.cuerpo,
              fecha: noticiaAPI.fecha,
              hora: noticiaAPI.hora,
              eliminada: noticiaAPI.eliminada === 0 ? false : true,
              visible: noticiaAPI.visible === 0 ? false : true,
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form
                noValidate
                onSubmit={handleSubmit}
                className="pl-md-5 pr-md-5"
              >
                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="validationFormik01">
                    {/* <Form.Label>First name</Form.Label> */}
                    <Form.Text
                      className="text-muted h5"
                      style={{ color: "rgba(66,62,55,.2) !important" }}
                    >
                      Titulo
                    </Form.Text>
                    <Form.Control
                      type="text"
                      name="titulo"
                      value={values.titulo}
                      onChange={handleChange}
                      isValid={touched.titulo && !errors.titulo}
                      isInvalid={!!errors.titulo}
                      style={{
                        border: "0",
                        borderBottom: "1px solid rgba(66,62,55,.2)",
                        borderRadius: 0,
                      }}
                    />

                    <Form.Control.Feedback>Correcto!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.titulo}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="validationFormik02">
                    {/* <Form.Label>Last name</Form.Label> */}
                    <Form.Text
                      className="text-muted h5"
                      style={{ color: "rgba(66,62,55,.2) !important" }}
                    >
                      Subtitulo
                    </Form.Text>
                    <Form.Control
                      type="text"
                      name="subtitulo"
                      value={values.subtitulo}
                      onChange={handleChange}
                      isValid={touched.subtitulo && !errors.subtitulo}
                      isInvalid={!!errors.subtitulo}
                      style={{
                        border: "0",
                        borderBottom: "1px solid rgba(66,62,55,.2)",
                        borderRadius: 0,
                      }}
                    />

                    <Form.Control.Feedback>Correcto!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.subtitulo}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="validationFormik03">
                    {/* <Form.Label>cuerpo</Form.Label> */}
                    <Form.Text
                      className="text-muted h5"
                      style={{ color: "rgba(66,62,55,.2) !important" }}
                    >
                      Cuerpo
                    </Form.Text>

                    <Form.Control
                      type="cuerpo"
                      name="cuerpo"
                      value={values.cuerpo}
                      onChange={handleChange}
                      isValid={touched.cuerpo && !errors.cuerpo}
                      isInvalid={!!errors.cuerpo}
                      style={{
                        border: "0",
                        borderBottom: "1px solid rgba(66,62,55,.2)",
                        borderRadius: 0,
                      }}
                    />

                    <Form.Control.Feedback>Correcto!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.cuerpo}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="validationFormik01">
                    {/* <Form.Label>First name</Form.Label> */}
                    <Form.Text
                      className="text-muted h5"
                      style={{ color: "rgba(66,62,55,.2) !important" }}
                    >
                      Fecha
                    </Form.Text>
                    <Form.Control
                      type="date"
                      name="fecha"
                      value={values.fecha}
                      onChange={handleChange}
                      isValid={touched.fecha && !errors.fecha}
                      isInvalid={!!errors.fecha}
                      style={{
                        border: "0",
                        borderBottom: "1px solid rgba(66,62,55,.2)",
                        borderRadius: 0,
                      }}
                    />

                    <Form.Control.Feedback>Correcto!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.fecha}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="validationFormik01">
                    {/* <Form.Label>First name</Form.Label> */}
                    <Form.Text
                      className="text-muted h5"
                      style={{ color: "rgba(66,62,55,.2) !important" }}
                    >
                      Hora
                    </Form.Text>
                    <Form.Control
                      type="time"
                      name="hora"
                      value={values.hora}
                      onChange={handleChange}
                      isValid={touched.hora && !errors.hora}
                      isInvalid={!!errors.hora}
                      style={{
                        border: "0",
                        borderBottom: "1px solid rgba(66,62,55,.2)",
                        borderRadius: 0,
                      }}
                    />

                    <Form.Control.Feedback>Correcto!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.hora}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="validationFormik01">
                    {/* <Form.Label>First name</Form.Label> */}
                    <Form.Text
                      className="text-muted h5"
                      style={{ color: "rgba(66,62,55,.2) !important" }}
                    >
                      Visible
                    </Form.Text>
                    <Form.Check
                      defaultChecked={noticia.visible === 0 ? false : true}
                      name="visible"
                      value={values.visible}
                      onChange={handleChange}
                      type={"checkbox"}
                    />

                    <Form.Control.Feedback>Correcto!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.horafin}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="validationFormik01">
                    {/* <Form.Label>First name</Form.Label> */}
                    <Form.Text
                      className="text-muted h5"
                      style={{ color: "rgba(66,62,55,.2) !important" }}
                    >
                      Eliminada
                    </Form.Text>
                    <Form.Check
                      defaultChecked={noticia.eliminada === 0 ? false : true}
                      name="eliminada"
                      value={values.eliminada}
                      onChange={handleChange}
                      type={"checkbox"}
                    />

                    <Form.Control.Feedback>Correcto!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.horafin}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md="12" className="pr-5 ">
                    <Form.Text
                      className="text-muted h5"
                      style={{ color: "rgba(66,62,55,.2) !important" }}
                    >
                      Imagenes
                    </Form.Text>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {noticiaAPI.noticia_imagenes.map(
                        (selectedFileInd, index) => {
                          return (
                            <div key={index}>
                              <img
                                alt=""
                                style={{ width: "12vw", height: "auto" }}
                                src={selectedFileInd.enlace}
                              />
                              <Button
                                variant="danger"
                                size="sm"
                                onClick={() => {
                                  setImageToDelete(selectedFileInd.id);
                                  handleShowEliminar(true);
                                }}
                              >
                                x
                              </Button>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} md="12" className="pr-5">
                    <Form.Text
                      className="text-muted h5"
                      style={{ color: "rgba(66,62,55,.2) !important" }}
                    >
                      Agregar mas imagenes
                    </Form.Text>
                    <Form.Control
                      multiple
                      name="fotos[]"
                      type="file"
                      onChange={changeHandler}
                    />
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {isSelected ? (
                        selectedFiles.map((selectedFileInd, index) => {
                          return (
                            <div key={index}>
                              <img
                                alt=""
                                style={{ width: "12vw", height: "auto" }}
                                src={URL.createObjectURL(selectedFileInd)}
                              />
                              <Button
                                variant="danger"
                                size="sm"
                                onClick={() => {
                                  removeItemFromArr(selectedFileInd);
                                }}
                              >
                                x
                              </Button>
                              {/* <p>Filename: {selectedFileInd.name}</p>
														<p>Filetype: {selectedFileInd.type}</p>
														<p>Size in bytes: {selectedFileInd.size}</p> */}
                            </div>
                          );
                        })
                      ) : (
                        <p />
                      )}
                    </div>
                    <Form.Row>
                      <Form.Group as={Col} md="12" className="pr-5">
                        {uploadPercentage > 0 && (
                          <ProgressBar
                            variant="info"
                            active
                            label={`${uploadPercentage}`}
                            now={uploadPercentage}
                          />
                        )}
                      </Form.Group>
                    </Form.Row>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md="12" className="pr-5">
                    <Button className="float-md-right btn-enviar" type="submit">
                      {loading ? (
                        <Spinner
                          animation="border"
                          size="sm"
                          style={{ marginBottom: "2px", marginRight: "3px" }}
                        />
                      ) : (
                        <div style={{ display: "none" }} />
                      )}
                      Confirmar edicion
                    </Button>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  {messageResponse != null ? (
                    <Alert variant="info" className="w-100 ">
                      {messageResponse}
                    </Alert>
                  ) : (
                    <div />
                  )}
                </Form.Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary btn-enviar"
            onClick={() => {
              handleGetNoticias();
              handleIsEdit(false);
              handleClose(false);
            }}
          >
            Volver
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        keyboard={false}
        backdrop="static"
        show={showEliminar}
        onHide={handleCloseEliminar}
      >
        <Modal.Header>
          <Modal.Title>Eliminar Imagen</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body> */}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEliminar}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={eliminarImgNoticia}>
            Eliminar imagen
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
