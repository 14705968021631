export const animationOne = {
	in: {
		opacity: 1
	},
	out: {
		opacity: 0
	}
};
export const animationTwo = {
	in: {
		opacity: 1
	},
	out: {
		opacity: 0
	}
};
export const animationThree = {
	in: {
		opacity: 1
	},
	out: {
		opacity: 0
	},
	end: {
		y: 0,
		opacity: 1
	}
};
export const transition = {
	duration: 0.4
};
