// import axios from 'axios';
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import LogoWp from "../../assets/images/logoWP1.png";
import "./Portada.css";
import { gsap } from "gsap";

export default function Portada() {
  const [noticiasAPI, setNoticiasAPI] = useState([]);
  // const [ loadingNoticias, setLoadingNoticias ] = useState(false);

  // const getNoticias = async () => {
  // 	setLoadingNoticias(true);

  // 	await axios
  // 		.get(
  // 			`http://localhost:8000/api/noticiasWeb`,
  // 			{ params: { limit: 3 } },
  // 			{
  // 				headers: {
  // 					Accept: 'application/json'
  // 				}
  // 			}
  // 		)
  // 		.then((res) => {
  // 			console.log(res.data.noticias);
  // 			if (res.data.noticias != null) {
  // 				setNoticiasAPI(res.data.noticias);
  // 			}
  // 			setLoadingNoticias(false);
  // 		});
  // };

  useEffect(() => {
    // getNoticias();
  }, []);

  useEffect(() => {
    let tl = gsap.timeline();
    tl.from(".tituloPortada", {
      opacity: 0,
      duration: 0.85,
      delay: 1.5,
      transform: "translateY(10px)",
    });
  }, []);

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.7,
        staggerChildren: 0.4,
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <div>
      <Container
        fluid
        style={{
          height: "60px",
          width: "100%",
          backgroundColor: "transparent",
          borderWidth: 1,
          borderColor: "white",
          position: "fixed",
          zIndex: 5,
        }}
      />
      <Container
        fluid
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          padding: "0px",
          margin: "0px",
        }}
      >
        <div
          className="containerVideo"
          style={{
            height: "100vh",
            width: "100%",
            position: "absolute",
            left: 0,
            top: 0,
            backgroundColor: "blue",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0px",
            overflow: "hidden",
          }}
        >
          <video className="videoPortada" loop autoPlay muted playsinline>
            <source
              src={require("../../assets/video/timelapse.mp4")}
              type="video/mp4"
            />
            Your browser does not support HTML video.
          </video>
        </div>
        <div
          style={{
            height: "100vh",
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "black",
            zIndex: 1,
            opacity: 0.25,
          }}
        />
        <div
          className="containerTextoss"
          style={{
            zIndex: 2,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1
            className="tituloPortada"
            style={{
              fontSize: "calc(1.5rem + 1.5vw)",
              color: "white",
              textAlign: "center",
              userSelect: "none",
              opacity: 1,
            }}
          >
            Direccionándote
          </h1>
        </div>
        <a
          rel="noopener noreferrer"
          href="https://wa.me/543875159478?text=Hola,%20necesito%20comunicarme%20con%20ustedes!%20"
          target="_blank"
        >
          <img
            alt=""
            // initial={{ rotate: 0, scale: 1 }}
            // animate={{ rotate: 18, scale: 1.2 }}
            // transition={{ yoyo: 3, duration: 1, ease: 'easeIn' }}
            src={LogoWp}
            className="iconoMsjWp"
            style={{}}
          />
        </a>

        <Container
          fluid
          style={{
            zIndex: 3,
            height: "30vh",
            backgroundColor: "transparent",
            position: "absolute",
            bottom: 0,
            padding: "0px",
          }}
        >
          <motion.ul
            style={{
              padding: "0",
              margin: "0",
              listStyle: "none",
              backgroundColor: "rgba(255,255,255,.2)",
            }}
            variants={container}
            initial="hidden"
            animate={noticiasAPI.length > 0 && "visible"}
            className="row"
          >
            {noticiasAPI.map((noticia) => (
              <motion.li
                key={noticia.idnoticia}
                className="col-4 col-sm-4 col-md-4 col-lg-4"
                variants={item}
                style={{ margin: "0px", padding: "0px", height: "30vh" }}
              >
                <div className="cardNoticia">
                  <div
                    style={{
                      position: "absolute",
                      height: "100%",
                      width: "100%",
                      zIndex: 5,
                      filter: "blur(8px)",
                      backgroundColor: "rgba(0,0,0,.3)",
                      clipPath: "polygon(0 100%, 0% 50%, 40% 100%)",
                    }}
                  />
                  <div
                    style={{
                      color: "white",
                      display: "flex",
                      paddingTop: "3vh",
                      paddingLeft: "2vh",
                      width: "80%",
                    }}
                    className=""
                  >
                    <div
                      style={{
                        color: "white",
                        fontSize: "calc(.6rem + .6vw)",
                        zIndex: 15,
                        textTransform: "uppercase",
                      }}
                    >
                      {`  ${"  	Noticia |  "}`}
                    </div>

                    <div
                      style={{
                        color: "rgba(0, 123, 130, 1)",
                        fontSize: "calc(.6rem + .6vw)",
                        zIndex: 15,
                        textTransform: "uppercase",
                      }}
                    >
                      {`  ${" "}`}
                      {`  ${"  Salta"}`}
                    </div>
                  </div>
                  <div
                    className=""
                    style={{
                      color: "white",
                      width: "80%",
                      paddingTop: "2vh",
                      paddingLeft: "2vh",
                      fontWeight: "800",
                      textTransform: "uppercase",
                      fontSize: "calc(.7rem + .7vw)",
                      textAlign: "start",
                      zIndex: 15,
                      wordBreak: "break-word",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {noticia.titulo}
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      left: "3vh",
                      bottom: "3vh",
                      zIndex: 15,
                    }}
                    initial={{ opacity: 0, x: 30 }}
                    animate={{ opacity: 1, x: 0 }}
                    //tengo que hacer variable que contenga initial y animate inside y agregarlo aca con variants. Entonces en animate podria poner lo mismo que en <ul
                    transition={{
                      ease: "easeInOut",
                      duration: 1.5,
                      delay: 1,
                      delayChildren: 5,
                    }}
                  >
                    <Link
                      to={`/noticia/${noticia.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      {/* <ArrowForwardIcon
												style={{
													color: 'white',
													width: '40px',
													height: '40px'
												}}
											/> */}
                      <motion.div
                        style={{
                          clipPath:
                            "polygon(20% 0%, 95% 0%, 80% 100%, 5% 100%)",
                          backgroundColor: "white",
                          color: "teal",
                          paddingTop: "3px",
                          paddingLeft: "20px",
                          paddingBottom: "3px",
                          paddingRight: "20px",
                          outline: "none",
                        }}
                        whileHover={{ scale: 1.15 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        Leer
                      </motion.div>
                    </Link>
                  </div>
                </div>
              </motion.li>
            ))}
          </motion.ul>
        </Container>
      </Container>
    </div>
  );
}
